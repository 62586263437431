const findDefault = ({ isDefault }) => !!isDefault

const moduleRouter = (company, data, router) => {
  const { getUserByEmail: user } = data
  const templates = user?.permissionTemplates || []
  if (templates.length === 0) {
    if (user?.superAdmin) router.push('/core/construction')
    else router.push('/403')
  } else {
    const template = templates.find(
      (template) =>
        template?.company?.id.toUpperCase() === company.toUpperCase()
    )

    let href
    if (template) {
      const module = template?.permissions?.modules?.find(findDefault)

      href = module
        ? `/${company.toLowerCase()}/${module?.name?.toLowerCase()}`
        : user?.superAdmin
        ? '/core/construction'
        : '/403'
      if (href == '/403') {
        console.log(
          '403 from moduleRouter because no module and not superadmin?',
          module,
          user
        )
      }
    } else {
      let module = templates[0]?.permissions?.modules?.find(findDefault)
      if (module) {
        href = `/${templates[0]?.company?.id.toLowerCase()}/${module?.name?.toLowerCase()}`
      } else {
        module = templates[0]?.permissions?.modules[0]
        href = `/${templates[0]?.company?.id.toLowerCase()}/${module?.name?.toLowerCase()}`
      }
    }
    router.push(href)
  }
}

export default moduleRouter
