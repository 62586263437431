import { useEffect } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import nextCookie from '@/utils/nextCookie'
import getDefaultUserRoute from '@/utils/passport/getDefaultUserRoute'

// loads the form component without ssr since it relies on localStorage
const LoginWithNoSSR = dynamic(() => import('@/components/Login/layout'), {
  ssr: false,
})

import { getCompanyFromUrl } from '@/utils'
import { useAuthUser, useCompany } from '@/utils/hooks'
import moduleRouter from '@/utils/auth/moduleRouter'

async function login({ email, password }) {
  await fetch('/api/auth/custom', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ username: email, password }),
  })
}

const LoginPage = ({ company }) => {
  const { setCompany } = useCompany()
  const { user } = useAuthUser()
  const router = useRouter()

  // sets the company
  useEffect(() => {
    setCompany(company)
  }, [company, setCompany])

  // routes logged in user to their default module
  useEffect(() => {
    if (user) {
      moduleRouter(company, { getUserByEmail: user }, router)
    }
  }, [user, company, router])

  return (
    <LoginWithNoSSR
      onEmployeeLogin={({ email }) => {
        window.location = `/api/auth/azuread-openidconnect?upn=${encodeURIComponent(
          email
        )}`
      }}
      onClientLogin={login}
      onForgotPassword={() => {}}
    />
  )
}

LoginPage.propTypes = {
  company: PropTypes.string.isRequired,
}

const getSession = (ctx) => {
  const cookies = nextCookie(ctx)
  const { passportSession } = cookies

  if (!passportSession) return undefined

  const serializedCookie = Buffer.from(passportSession, 'base64').toString()
  const { passport: { user = undefined } = {} } = JSON.parse(serializedCookie)

  if (!user) return undefined

  return user
}

export const getServerSideProps = async (ctx) => {
  const company = getCompanyFromUrl({
    url: ctx.req.headers.host,
  })

  const session = getSession(ctx)

  if (session) {
    return {
      props: { company },
      redirect: {
        destination: await getDefaultUserRoute(ctx.req, session),
        permanent: false,
      },
    }
  } else {
    return { props: { company } }
  }
}

export default LoginPage
